import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-accordion-body pt-4" }
const _hoisted_2 = { class: "row mb-1" }
const _hoisted_3 = { class: "col-6 d-flex" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-6 mt-3" }
const _hoisted_7 = { class: "col-6 mt-3" }
const _hoisted_8 = { class: "col-6 mt-3" }
const _hoisted_9 = { class: "col-6 mt-3" }
const _hoisted_10 = { class: "col-6 mt-3" }
const _hoisted_11 = { class: "col-6 mt-3" }
const _hoisted_12 = { class: "col-6 mt-3" }
const _hoisted_13 = { class: "col-3 d-flex" }
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "mt-3" }
const _hoisted_16 = { class: "mt-3" }
const _hoisted_17 = { class: "mt-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutosizeTextarea = _resolveComponent("AutosizeTextarea")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_Card = _resolveComponent("Card")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TextErrorMessage = _resolveComponent("TextErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Card, { title: "TRANSACTION details" }, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              class: "pb-4",
              onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AutosizeTextarea, {
                    id: _ctx.id,
                    modelValue: _ctx.newDescription,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDescription) = $event)),
                    readonly: "true",
                    "input-type": "material",
                    label: "Description"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-status`,
                    modelValue: _ctx.paymentStatus,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paymentStatus) = $event)),
                    label: "Status",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-recipient-id`,
                    modelValue: _ctx.userId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userId) = $event)),
                    label: "Recipient ID",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-amount`,
                    modelValue: _ctx.amount,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.amount) = $event)),
                    label: "Amount",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-code`,
                    modelValue: _ctx.authCode,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.authCode) = $event)),
                    label: "Authorization Code",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-type`,
                    modelValue: _ctx.paymentTypeInfo,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.paymentTypeInfo) = $event)),
                    label: "Payment Type",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-transaction-id`,
                    modelValue: _ctx.transactionId,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.transactionId) = $event)),
                    label: "Transaction ID",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_AppInput, {
                    id: `details:${_ctx.id}-date`,
                    modelValue: _ctx.paymentDate,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.paymentDate) = $event)),
                    label: "Date of Payment",
                    readonly: "true",
                    "input-type": "material"
                  }, null, 8, ["id", "modelValue"])
                ])
              ])
            ], 32)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Card, { title: "Payment owner" }, {
          default: _withCtx(() => [
            _createVNode(_component_UserInfo, {
              class: "w-100",
              avatar: _ctx.user.image,
              description: `User ID: ${_ctx.user.id}`,
              name: _ctx.user.firstName + ' ' +_ctx.user.lastName
            }, null, 8, ["avatar", "description", "name"]),
            _createElementVNode("form", {
              class: "mt-5",
              onSubmit: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_AppInput, {
                  id: `owner:${_ctx.id}-source`,
                  modelValue: _ctx.auth,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.auth) = $event)),
                  label: "Source",
                  readonly: "true",
                  "input-type": "material"
                }, null, 8, ["id", "modelValue"])
              ])
            ], 32)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.status !== 'Refund')
          ? (_openBlock(), _createBlock(_component_Card, {
              key: 0,
              title: "Refund"
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  class: "pb-4",
                  onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_AppInput, {
                      id: `refund-${_ctx.id}-amount`,
                      modelValue: _ctx.amount,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.amount) = $event)),
                      "input-type": "material",
                      "show-controls": false,
                      label: "Amount",
                      readonly: true,
                      type: "number"
                    }, null, 8, ["id", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_AppInput, {
                      id: `refund-${_ctx.id}-reason`,
                      modelValue: _ctx.refund.reason,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.refund.reason) = $event)),
                      "is-textarea": true,
                      "input-type": "material",
                      label: "Return reason",
                      type: "number",
                      rows: "5"
                    }, null, 8, ["id", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_AppButton, {
                      size: "lg",
                      class: "w-100",
                      type: "danger",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.createRefund(_ctx.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Refund Payment ")
                      ]),
                      _: 1
                    })
                  ])
                ], 32)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_Card, {
              key: 1,
              title: "Refund"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TextErrorMessage, { message: "Payment refunded" }),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_AppButton, {
                    type: "danger",
                    size: "lg",
                    class: "w-100"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Send Receipt ")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
      ])
    ])
  ]))
}