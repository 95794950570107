
import { defineComponent, PropType } from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { getDaysByDateTitle } from "@/utills/getDaysByDateTitle";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ChoosePeriodSelect from "@/components/ui/Calendar/ChoosPeriodSelect.vue";
// import PaymentsStatistic from "@/components/Payments/PaymentsStatistic.vue";
import AppTableAccordion from "./AppTableAccordion.vue";
import AppPaymentsHeading from "@/components/Table/ts/instances/payments/AppPaymentsHeading.vue";
import AppPaymentsBody from "@/components/Table/ts/instances/payments/AppPaymentsBody.vue";
import Accordion from "@/components/ui/Accourdion/Accordion.vue";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import TableRow from "@/components/Table/TableRow.vue";
import moment from "moment";
import { IPayment } from "@/api/interfaces/payments/common";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import TableFooter from "@/components/Table/TableFooter.vue";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
export type tableHeadCellsList = ITableHeadCell[];
export type tableBodyList = ITableBodyRow[];

export default defineComponent({
  name: "AppPayments",
  components: {
    AppTableController,
    TableFooter,
    TableRow,
    Accordion,
    AppPaymentsBody,
    AppPaymentsHeading,
    AppTableAccordion,
    ChoosePeriodSelect,
    PageMainHeading,
    // PaymentsStatistic
  },
  inheritAttrs: false,
  emits: {
    changeViewConfiguration: null,
    changeFilter: null,
    refundCreate: null
  },
  props: {
    paginationCaptureKey: {
      type: String,
      default: ""
    },
    totalRows: { type: Number as PropType<number>, default: 0 },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: 1 },

    tableBody: {
      type: Array as PropType<IPayment[]>,
      required: true
    },
    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      required: true
    },
    hideButton: { type: Boolean, default: false }
  },
  data() {
    return {
      dates: this.tableBody,
      viewConfiguration: {
        perPage: this.perPage,
        page: this.currentPage
      }
    } as {
      viewConfiguration: ITableViewConfiguration;
    };
  },

  computed: {
    getData() {
      return this.tableBody;
    },
    paginationTotal(): number {
      if (this.totalRows === 0) {
        return 0;
      }

      return Math.ceil(this.totalRows / this.perPage);
    },
    paginationCapture(): string {
      if (!this.paginationCaptureKey) {
        return "";
      }

      const { totalRows = 0 } = this;
      const { perPage, page } = this.viewConfiguration;
      let count = totalRows > perPage ? perPage * page : this.totalRows;

      if (count > totalRows) {
        count = totalRows;
      }

      return `${count} of ${this.totalRows} ${this.paginationCaptureKey}`;
    },
    getDays(date: Date) {
      return getDaysByDateTitle(date.toString());
    },

    newTableBody() {
      return this.tableBody.map(
        ({
          type,
          description,
          user,
          amount,
          date,
          status,
          id
        }: {
          type: string;
          description: string;
          user: IUserAccount;
          amount: number;
          date: string;
          status: string;
          id: number;
        }) => ({
          type,
          description,
          user,
          amount,
          date: this.formatDate(date),
          status,
          id
        })
      );
    }
  },
  watch: {
    viewConfiguration: {
      handler({ page, perPage }: ITableViewConfiguration) {
        if (page === this.currentPage && perPage === this.perPage) {
          return;
        }

        this.$emit("changeViewConfiguration", this.viewConfiguration);
      },
      deep: true
    }
  },
  methods: {
    changeFilterHandler(data: any) {
      this.$emit("changeFilter", data);
    },
    createRefund(data: CreateRefundDto, photo: string) {
      this.$emit("refundCreate", data, photo);
    },
    totalPayments() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      } else return this.perPage * this.currentPage;
    },
    formatDate(date: string) {
      const result = moment(date)
        .local()
        .format("D.MM.YYYY");
      return `${result} at ${moment(date)
        .local()
        .format("h:mm a")}`;
    },
    formatDateForGroup(date: string) {
      if (moment(date).isSame(moment(), "day")) {
        return "Today";
      }
      if (moment(date).isSame(moment().subtract(1, "day"), "day")) {
        return "Yesterday";
      }
      return moment(date).format("D MMM YYYY");
    },
    changeCurrentPageHandler(page: number): void {
      this.viewConfiguration.page = page;
    },

    changePerPageHandler(perPage: number): void {
      this.viewConfiguration.perPage = perPage;
    }
  }
});
