
import { defineComponent, PropType } from "vue";
import AppInput from "../../../../Form/AppInput.vue";
import Card from "../../../../ui/Card/Card.vue";
import UserInfo from "@/components/UserInfo/UserInfo.vue";
import TextErrorMessage from "../../../../Errors/TextErrorMessage.vue";
import AppButton from "../../../../ui/Buttons/AppButton.vue";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea.vue";
import { IUserAccount } from "@/store/interfaces/accounts/IUserAccount";
import { IPaymentInfo, IProduct } from "@/api/interfaces/payments/common";

export default defineComponent({
  name: "AppPaymentsBody",
  components: {
    AppButton,
    AutosizeTextarea,
    TextErrorMessage,
    UserInfo,
    Card,
    AppInput
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    user: {
      type: Object as PropType<IUserAccount>,
      required: true
    },
    product: {
      type: Object as PropType<IProduct>,
      required: true
    },
    payment: {
      type: Object as PropType<IPaymentInfo>,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    userLocation: {
      type: Object as PropType<IUserAccount>,
      required: true
    }
  },
  emits: {
    createRefund: null
  },
  data() {
    return {
      description: this.newDescription,
      paymentStatus: this.status,
      userId: this.user.id,
      amount: this.product.priceWithDiscount,
      authCode: "-",
      paymentTypeInfo: this.paymentType,
      transactionId: this.payment.transactionId,
      paymentDate: this.date,
      userInfo: this.userLocation,
      auth: "Verum App",
      refund: {
        amount: 0,
        reason: ""
      }
    };
  },
  computed: {
    newDescription(): string {
      return `${this.getLocation}, ${this.getPlan} eSIM package purchase with FREE eSIM Profile`;
    },
    getLocation() {
      const { country, region, subregion } = this.product;
      if (country) {
        return country.name;
      } else if (region) {
        return region.name;
      } else {
        return subregion.name;
      }
    },
    getPlan(): string {
      return `${this.product.plan.name +
        "-" +
        this.product.title +
        "-" +
        this.product.validiti}`;
    }
  },
  methods: {
    createRefund(id: number) {
      this.$emit(
        "createRefund",
        {
          paymentId: id,
          description: this.refund.reason,
          chargeId: this.payment.chargeId
        },
        this.user.image
      );
    }
  }
});
